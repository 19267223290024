// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./itservis.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App {\n  text-align: center;\n  height: 100vh;\n  width: 100%;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  opacity: 0.8; /* Полупрозрачный фон */\n  filter: alpha(Opacity=0.7); /* Прозрачность в IE */\n}\n \n\n\n\n\n\n\n", "",{"version":3,"sources":["webpack://src/component/App/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;EAC3B,yDAAqC;EACrC,YAAY,EAAE,uBAAuB;EACrC,0BAA0B,EAAE,sBAAsB;AACpD","sourcesContent":[".App {\n  text-align: center;\n  height: 100vh;\n  width: 100%;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-image: url(\"itservis.jpg\");\n  opacity: 0.8; /* Полупрозрачный фон */\n  filter: alpha(Opacity=0.7); /* Прозрачность в IE */\n}\n \n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
